import { fieldRequiredByBlur, fieldRequiredByBlurAndChange, fieldTypeEmail } from '@/helpers/validations'

export default
{
  serviceId: [fieldRequiredByBlurAndChange],
  planId: [fieldRequiredByBlurAndChange],
  companyServiceFrequencyBilling: [fieldRequiredByBlurAndChange],
  userFirstName: [fieldRequiredByBlur],
  userLastName: [fieldRequiredByBlur],
  userName: [fieldRequiredByBlur],
  userEmail: [fieldRequiredByBlur, fieldTypeEmail],
}
