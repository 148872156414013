<template>
  <div>
    <el-form
      ref="form"
      v-loading="form.loading"
      :model="form.model"
      :rules="form.rules"
      label-position="top"
      status-icon
    >
      <el-row :gutter="20">
        <el-col v-if="roleIsAdministrator" :span="24">
          <el-form-item>
            <el-button
              size="mini"
              type="primary"
              :loading="form.loading"
              style="float: right"
              @click.prevent="onSubmit"
            >
              {{ $tc('save') }}
            </el-button>
          </el-form-item>
        </el-col>
        <el-col :sm="12">
          <el-form-item
            v-if="modeIsAdd"
            :label="$tc('service')"
            prop="serviceId"
          >
            <select-service
              v-model="form.model.serviceId"
              :company-key="form.model.companyKey"
            ></select-service>
          </el-form-item>
          <el-form-item :label="$tc('plan')" prop="planId">
            <select-service-plan-id
              v-model="form.model.planId"
              :service-id="form.model.serviceId"
              @set-selected="onSelectedPlan"
            >
            </select-service-plan-id>
          </el-form-item>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item
                :label="$tc('recurrence')"
                prop="companyServiceFrequencyBilling"
              >
                <select-service-recurrence
                  v-model="form.model.companyServiceFrequencyBilling"
                ></select-service-recurrence>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$tc('dateNextInvoice')"
                prop="companyServiceDateNextInvoice"
              >
                <el-date-picker
                  v-model="form.model.companyServiceDateNextInvoice"
                  type="date"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item :label="$tc('price')" prop="companyServicePrice">
            <el-input
              v-model.number="form.model.companyServicePrice"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$tc('seller')" prop="vendorUserId">
            <select-user-is-seller v-model="form.model.vendorUserId">
            </select-user-is-seller>
          </el-form-item>
        </el-col>
        <el-col :sm="12">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item
                :label="$tc('dateFirstInvoice')"
                prop="companyServiceBillingStartDate"
              >
                <el-date-picker
                  v-model="form.model.companyServiceBillingStartDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col
              v-if="
                form.model.companyServiceStatus &&
                !['activa'].includes(
                  form.model.companyServiceStatus.toLowerCase(),
                )
              "
              :span="12"
            >
              <el-form-item
                :label="$tc('dateEndTrialPeriod')"
                prop="companyServiceEndDateTrialPeriod"
              >
                <el-date-picker
                  v-model="form.model.companyServiceEndDateTrialPeriod"
                  type="date"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item :label="$tc('observation')" prop="companyServiceNote">
            <el-input
              v-model="form.model.companyServiceNote"
              type="textarea"
              placeholder="Observacion"
            ></el-input>
          </el-form-item>
          <el-card shadow="always">
            <div slot="header" class="clearfix">
              <span
                >{{ $tc('total', 2) }}
                <small
                  >/
                  {{
                    $tc(toCamelCase(form.model.companyServiceFrequencyBilling))
                  }}</small
                >
              </span>
            </div>
            <table width="100%">
              <col style="width: 65%" />
              <col style="width: 35%" />
              <tfoot>
                <tr>
                  <td>{{ $tc('plan', 1) }}</td>
                  <td align="right">
                    {{
                      companyPlan.companyServicePlanTaxBase
                        | formatToCurrencyWithIsoCode
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('feature', 2) }}</td>
                  <td align="right">
                    {{
                      companyPlanFeature.companyServiceFeatureTaxBase
                        | formatToCurrencyWithIsoCode
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('addOn') }}</td>
                  <td align="right">
                    {{
                      addOn.companyServiceAddOnPrice
                        | formatToCurrencyWithIsoCode
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('iva') }}</td>
                  <td align="right">
                    {{
                      total.companyServiceIvaValue | formatToCurrencyWithIsoCode
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('total') }}</td>
                  <td align="right">
                    {{
                      total.companyServiceAmountTotal
                        | formatToCurrencyWithIsoCode
                    }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
    <el-form
      v-if="modeIsAdd"
      ref="formUser"
      v-loading="formUser.loading"
      :model="formUser.model"
      :rules="formUser.rules"
      label-position="top"
      status-icon
    >
      <h4 style="float: center; text-align: center">
        {{ $tc('user') }}
      </h4>
      <br />
      <el-row :gutter="20">
        <el-col :sm="12">
          <el-form-item :label="$tc('userName', 1)" prop="userName">
            <el-input
              v-model="formUser.model.userName"
              :maxlength="32"
              placeholder="Ejemplo: pedroperez"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$tc('email')" prop="userEmail">
            <el-input
              v-model="formUser.model.userEmail"
              type="email"
              :maxlength="160"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="12">
          <el-form-item :label="$tc('firstName')" prop="userFirstName">
            <el-input
              v-model="formUser.model.userFirstName"
              :maxlength="64"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$tc('lastName')" prop="userLastName">
            <el-input
              v-model="formUser.model.userLastName"
              :maxlength="64"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-card shadow="always">
      <div slot="header" class="clearfix">
        <span>{{ $tc('feature', 2) }} </span>
      </div>
      <el-tabs v-model="tab.plan.active" type="card">
        <el-tab-pane key="current" :label="$tc('featureOfCompany')">
          <table
            class="cell-padding"
            width="100%"
            cellpadding="3"
            cellspacing="5"
          >
            <col style="width: 40%" />
            <col style="width: 15%" />
            <col style="width: 15%" />
            <col style="width: 15%" />
            <col style="width: 15%" />
            <thead>
              <tr>
                <th>{{ $tc('name', 1) }}</th>
                <th>{{ $tc('value') }}</th>
                <th>{{ $tc('addOnQuantity', 2) }}</th>
                <th>
                  {{ $tc('addOnPrice', 2) }}
                  <br />
                  (RECURRENCIA)
                </th>
                <th>{{ $tc('total') }}</th>
              </tr>
            </thead>
            <tbody>
              <plan-feature-row
                v-for="(item, index) in form.model.features"
                :key="index"
                :company-service-frequency-billing="
                  form.model.companyServiceFrequencyBilling
                "
                :feature.sync="item"
                @update-billed-value="
                  onUpdateFeatureBilledValue(form.model.features, index, $event)
                "
              ></plan-feature-row>
            </tbody>
          </table>
        </el-tab-pane>
        <el-tab-pane
          v-if="!modeIsAdd"
          key="original"
          :label="$tc('featureOfPlan')"
        >
          <el-alert
            type="info"
            :description="$tc('takeFromOriginalPlanMessage')"
            :title="$tc('takeFromOriginalPlan')"
            show-icon
          >
            <template slot="title">
              <el-switch
                v-model="form.model.featuresTakeThePlan"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="true"
                inactive-value="false"
              ></el-switch>
              {{ $tc('takeFromOriginalPlan') }}
            </template>
          </el-alert>
          <table
            v-if="tab.plan.active === '1'"
            class="cell-padding"
            width="100%"
            cellpadding="3"
            cellspacing="5"
          >
            <col style="width: 40%" />
            <col style="width: 15%" />
            <col style="width: 15%" />
            <col style="width: 15%" />
            <col style="width: 15%" />
            <thead>
              <tr>
                <th>{{ $tc('name', 1) }}</th>
                <th>{{ $tc('value') }}</th>
                <th>{{ $tc('addOnQuantity', 2) }}</th>
                <th>{{ $tc('addOnPrice', 2) }}</th>
                <th>{{ $tc('total') }}</th>
              </tr>
            </thead>
            <tbody>
              <plan-feature-row
                v-for="(item, index) in form.model.plan.features"
                :key="index"
                :company="form.model"
                :feature.sync="item"
                @update-billed-value="
                  onUpdateFeatureBilledValue(
                    form.model.plan.features,
                    index,
                    $event,
                  )
                "
              ></plan-feature-row>
            </tbody>
          </table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-card shadow="always">
      <div slot="header" class="clearfix">
        <span>{{ $tc('addOn', 2) }} </span>
      </div>
      <el-form ref="formAddOn" :inline="true" :model="formAddOn.model">
        <el-form-item :label="$tc('addOn')" prop="id">
          <select-add-on
            v-model="formAddOn.model.id"
            :plan-id="form.model.planId"
            :service-id="form.model.serviceId"
            @set-selected="formAddOn.model.addOn = $event"
          ></select-add-on>
        </el-form-item>
        <el-form-item v-if="roleIsAdministrator">
          <el-button size="mini" type="primary" @click="onInsertAddOn">
            {{ $tc('add') }}
          </el-button>
        </el-form-item>
      </el-form>
      <table class="cell-padding" width="100%" cellpadding="3" cellspacing="5">
        <col style="width: 5%" />
        <col style="width: 35%" />
        <col style="width: 15%" />
        <col style="width: 15%" />
        <col style="width: 15%" />
        <col style="width: 15%" />
        <thead>
          <tr>
            <th></th>
            <th>{{ $tc('name', 1) }}</th>
            <th>{{ $tc('price') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in form.model.addOns" :key="index">
            <td>
              <el-button
                v-if="roleIsAdministrator"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                circle
                @click="onRemoveAddOn(index)"
              ></el-button>
            </td>
            <td>
              {{ item.addOnName }}
              <small v-if="item.addOnBillRecurrence === 'ByPlan'"
                >/
                {{
                  $tc(toCamelCase(form.model.companyServiceFrequencyBilling))
                }}.</small
              >
              <small v-if="item.addOnBillRecurrence === 'Initial'"
                >/ {{ $tc('paymentOnlyTime') }}.</small
              >
              &nbsp;
              <el-tooltip
                v-if="item.addOnDescription"
                :content="item.addOnDescription"
                class="item help"
                effect="dark"
                placement="top"
              >
                <i class="fad fa-question"></i>
              </el-tooltip>
            </td>
            <td>
              {{ item.addOnPrice | formatToCurrencyWithIsoCode }}
            </td>
          </tr>
        </tbody>
      </table>
    </el-card>
  </div>
</template>
<script>
/* app
-------------------------------------------------- */
import { mapFilters } from '@/filters'
import { fieldRequiredByBlurAndChange } from '@/helpers/validations'
import form from '@/views/common/form'
import update from '@/views/common/update'
import modelUser from '@/views/user/model'
import rulesUser from '@/views/user/rules'
import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'
import { mapGetters } from 'vuex'
import {
  addOnTotalize,
  addOns,
  companyPlan,
  companyPlanFeatures,
  total,
} from './calculate-total'
import model from './model'
import rules from './rules'

/* End of app
-------------------------------------------------- */

/* vue
-------------------------------------------------- */

/* End of vue
-------------------------------------------------- */

/* use
-------------------------------------------------- */
Vue.use(AsyncComputed)

/* End of use
-------------------------------------------------- */

export default {
  extends: update('vouchers', { model }),
  components: {
    planFeatureRow: _ => import('./plan-feature-row'),
  },
  props: {
    mode: {
      default: 'add',
    },
  },
  data() {
    return {
      form: form({ model, rules }),
      formUser: form({ model: modelUser, rules: rulesUser }),
      formAddOn: form({
        model: () => ({
          id: '',
          addOn: {},
        }),
        rules: { id: [fieldRequiredByBlurAndChange] },
      }),
      tab: {
        plan: {
          active: 0,
        },
      },
    }
  },
  methods: {
    ...mapFilters(['toCamelCase']),
    onInsertAddOn() {
      const _this = this
      _this.$refs.formAddOn.validate(valid => {
        if (valid) {
          addOnTotalize(_this.formAddOn.model.addOn)
          _this.form.model.addOns.push(
            addOnTotalize(_this.formAddOn.model.addOn),
          )
        }
      })
    },
    onRemoveAddOn(index) {
      this.form.model.addOns.splice(index, 1)
    },
    async onSelectedPlan(plan) {
      const _this = this
      if (plan) {
        _this.form.loading = true
        _this.form.model.planId = plan.id
        _this.form.loading = false
        _this.form.model.plan = plan

        if (_this.form.model.features.length < 1 || _this.modeIsAdd) {
          _this.form.model.features = plan.features
        }
      }
    },
    onSelectedInitPlan(plan) {
      if (plan) {
        this.form.model.plan = plan
      }
    },
    async onSubmit() {
      const _this = this
      _this.$refs.form.validate(async valid => {
        if (valid) {
          if (_this.form.model.featuresTakeThePlan === 'true') {
            _this
              .$confirm(
                _this.$tc('overwriteFeaturesCompanyService'),
                `${_this.$tc('wait')} !!`,
                {
                  confirmButtonText: _this.$tc('yes'),
                  cancelButtonText: _this.$tc('cancel'),
                  type: 'warning',
                },
              )
              .then(async () => await _this.updateCompanyService())
              .catch()
          } else {
            await _this.updateCompanyService()
          }
        } else {
          _this.$message.error('Oops, hay datos requeridos que debes ingresar!')

          return false
        }
      })
    },
    onUpdateFeatureBilledValue(features, index, value) {
      if (features && features.length > 0) {
        features[index].featureBilledValue = value
      }
    },
    fillForm(value) {
      const _this = this
      if (_this.modeIsAdd) {
        _this.form.model.id = value.id
        _this.form.model.companyKey = value.companyKey
        _this.form.model.tenantId = value.tenantId
        _this.form.model.companyName = value.companyName
        _this.form.model.identificationTypeName = value.identificationTypeName
        _this.form.model.companyIdentification = value.companyIdentification
        _this.form.model.companyIdentificationCheck =
          value.companyIdentificationCheck
        _this.form.model.identificationTypeId = value.identificationTypeId
        _this.form.model.commonPersonId = value.commonPersonId
        _this.form.model.companyPhone = value.companyPhone
        _this.form.model.companyEmail = value.companyEmail
        _this.form.model.features = []
        _this.form.model.addOns = []
        _this.getSuperAdministrator(value.serviceId)
      } else {
        _this.form.model = {
          ...value,
        }

        _this.form.model.features = []

        _this.$http
          .get(
            `companies/${_this.form.model.companyKey}/${_this.form.model.serviceId}/features`,
          )
          .then(result => {
            const subscriptionFeatures = result.data
            console.log('SubscriptionFeatures---', subscriptionFeatures)

            subscriptionFeatures.forEach(subscriptionFeature => {
              _this.form.model.features.push({
                id: subscriptionFeature.featureId,
                featureName: subscriptionFeature.featureName,
                featureType: subscriptionFeature.featureType,
                featureValue:
                  subscriptionFeature.featureType === 'closedLimit' ||
                  subscriptionFeature.featureType === 'openLimit'
                    ? subscriptionFeature.featureQuantity.toString()
                    : subscriptionFeature.featureAccess.toString(),
                featureAddonQuantity:
                  subscriptionFeature.featureType === 'closedLimit' ||
                  subscriptionFeature.featureType === 'openLimit'
                    ? subscriptionFeature.featureQuantityAddon.toString()
                    : subscriptionFeature.featureAccessAddon.toString(),
                featureUnitPriceCustom: subscriptionFeature.featurePriceAddon,
                featureIcon: subscriptionFeature.featureIcon,
                featureIsControlled: subscriptionFeature.featureIsControlled,
                featureIsVisible: subscriptionFeature.featureIsVisible,
                featureDescription: subscriptionFeature.FeatureDescription,
                featureOrder: 0,
                featureEnabled: 1,
                featureBilledValue: 0,
              })
            })

            console.log('Features---', _this.form.model.features)
          })

        _this.$http
          .get(`plans/${_this.form.model.planId}`)
          .then(result => (_this.form.model.plan = result.data))
      }
    },
    getSuperAdministrator(serviceId) {
      const _this = this
      if (_this.modeIsAdd) {
        _this.formUser.loading = true
        _this.$http
          .get(
            `users/${_this.form.model.companyKey}/${serviceId}/findSuperAdministrator`,
          )
          .then(result => {
            _this.formUser.model = { ...result.data }
          })
          .finally(_ => (_this.formUser.loading = false))
      }
    },
    updateCompanyService() {
      const _this = this
      const action = _this.mode === 'add' ? 'post' : 'patch'
      let entity =
        _this.mode === 'add'
          ? { company: _this.form.model, user: _this.formUser.model }
          : _this.form.model
      _this.form.loading = true
      _this.formUser.loading = true
      if (_this.form.model.featuresTakeThePlan) {
        entity = {
          ...entity,
          ...{
            features: _this.form.model.plan.features,
          },
        }
      }
      _this.$http[action](`companies/${_this.form.model.id}/services`, entity)
        .then(response => {
          _this.$emit('saved')
          _this.$message({
            message: _this.$tc('processSuccess'),
            type: 'success',
          })
        })
        .finally(_ => {
          _this.form.loading = false
          _this.formUser.loading = false
        })
    },
  },
  computed: {
    ...mapGetters(['setting']),
    roleIsAdministrator: _this => [1, 2].includes(+_this.setting.roleId),
    modeIsAdd: _this => _this.mode === 'add',
    features: _this =>
      _this.form.model.featuresTakeThePlan === 'true'
        ? _this.form.model.plan.features
        : _this.form.model.features,
    companyPlan: _this =>
      companyPlan(_this.form.model.companyServicePrice, _this.form.model.plan),
    companyPlanFeature: _this =>
      companyPlanFeatures(_this.features, _this.form.model.plan),
    addOn: _this => addOns(_this.form.model.addOns),
    total: _this =>
      total(_this.companyPlan, _this.companyPlanFeature, _this.addOn),
  },
  mounted() {},
}
</script>
<style lang="scss" scoped>
div {
  &::v-deep .cell-padding td {
    padding: 0.2em;
  }
}
</style>
